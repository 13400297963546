import { defineNuxtRouteMiddleware, navigateTo } from 'nuxt/app';
import { useAuthStore } from '@/stores/auth';
export default defineNuxtRouteMiddleware((to) => {
  const authStore = useAuthStore();

  if (!authStore.isAuthorized) {
    return navigateTo({
      path: '/auth/login',
      query: {
        ...to.query,
        needLogin: true,
        authRedirect: to.path,
      },
    });
  }
});
